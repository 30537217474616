<template>
  <div id="app">
    <router-view />
    <lg-preview></lg-preview>
  </div>
</template>

<script>
export default {
    mounted() {
     if(window.navigator.userAgent.indexOf('MicroMessenger') > -1){
       this.$router.push({path:'/wx'})
     }
     else{
       this.$router.push({path:'/'})
     }
    }
};
</script>
<style>
*{
  padding: 0;
  margin: 0;
  list-style: none;
}
html,body,#app{
  width: 100%;
  height: 100%;
}
</style>
